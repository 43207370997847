import React from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import {ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider} from "pure-react-carousel";
import userStore from "../../stores/userStore"
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../../helper/util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import history from "../../helper/browserHistory";

const MIN_WIDTH = 767;

@observer
class ImageCarouselComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            image: {
                name: "",
                type: "",
                link: "",
                site_link: "",
                slide_teaser: "",
                slide_header: ""
            },
            images: [],
            selectImages: [],
            selectModalOpen: false,
            currentWidth: 802

        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({...this.state, currentWidth: window.innerWidth});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
        this.fetchImagesFrontendId();
        this.fetchAllImages();
    }

    fetchAllImages() {
        fetch(config.BASE_URL + "images/web", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    let allImages = [];
                    for (let i = 0; i < json.length; i++) {
                        allImages.push({
                            link: json[i],
                            selected: false
                        })
                    }
                    this.setState({...this.state, selectImages: allImages});
                });

            } else {
                console.log(response.status)
            }
        }).catch(
            error => {
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    addSelectedImages() {
        let linksToPut = [];

        for (let i = 0; i < this.state.selectImages.length; i++) {
            if (this.state.selectImages[i].selected) {
                //check if already in links
                let isIn = false;
                for (let j = 0; j < this.state.images.length; j++) {
                    if (this.state.images[j].link === this.state.selectImages[i].link) {
                        isIn = true;
                        break;
                    }
                }
                if (!isIn) {
                    linksToPut.push(this.state.selectImages[i].link);
                }
            }
        }

        if (linksToPut.length > 0) {
            let self = this;
            //put links to component
            fetch(config.BASE_URL + 'images/selection/', {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify({frontendId: this.props.id, links: linksToPut})
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    self.fetchImagesFrontendId();
                    self.fetchAllImages();
                    self.setState({...self.state, selectModalOpen: false})
                }
            }).catch(() => {
            });
        } else {
            this.setState({...this.state, selectModalOpen: false})
        }
    }

    fetchImagesFrontendId() {
        let frontendId = this.props.id;
        fetch(config.BASE_URL + "images/frontendId/" + frontendId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.setState({...this.state, images: json})
                });

            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    deleteImage(index) {
        let images = this.state.images;
        fetch(config.BASE_URL + "images/", {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: images[index].id})
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }


    getGalleryItems(images) {
        let items = [];
        for (let i = 0; i < images.length; i++) {
            items.push(
                <Slide index={i} key={i} ariaLabel={"slide" + i}>
                    <Responsive as={"div"} minWidth={MIN_WIDTH + 1}
                                className={"slider-image-container"}
                                style={{background: "url('" + images[i].link + "')", cursor: "pointer"}}
                                onClick={() => images[i].site_link.length > 0 ?
                                    window.open(images[i].site_link, "_blank") :
                                    null}>
                        <div style={{position: "absolute", bottom: "0px", paddingBottom: "0px"}}>
                            {/*images[i].slide_header.length > 0 ?
                                <h2 className={"slider-header"}>{images[i].slide_header}</h2>
                                :
                                null
                           */}
                            {images[i].slide_teaser.length > 0 ?
                                <div className={"slider-teaser"}>
                                    {images[i].slide_teaser}
                                </div>
                                :
                                null
                            }
                        </div>
                    </Responsive>
                    <Responsive as={"div"} maxWidth={MIN_WIDTH}>
                        <div className={"slider-image-container"}
                             style={{background: "url('" + images[i].link + "')", cursor: "pointer"}}
                             onClick={() => images[i].site_link.length > 0 ?
                                 window.open(images[i].site_link, "_blank") :
                                 null}/>
                        {images[i].slide_teaser.length > 0 ?
                            <div className={"slider-teaser"}>
                                {images[i].slide_teaser}
                            </div>
                            :
                            null
                        }
                    </Responsive>

                </Slide>
            )
        }
        return items;
    }

    uploadThumbnailImage(event) {
        let self = this;
        const data = new FormData();

        let files = Array.from(event.target.files);

        //Send files
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            data.append("images", file);
        }
        let frontendId = this.props.id;
        fetch(config.BASE_URL + 'images/uploadImage/' + frontendId, {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.fetchImagesFrontendId();
                self.fetchAllImages();
            }
        }).catch(() => {
        });

    }

    getGalleryDots(images, videos) {
        let items = [];

        for (let i = 0; i < images.length; i++) {
            items.push(
                <Dot index={i} slide={i} className={"preview-dot"}
                     style={{"backgroundImage": "url(" + images[i] + ")"}}>
                    <p className={"empty-handtool-spec"}>.</p>
                </Dot>
            )
        }

        for (let i = 0; i < videos.length; i++) {
            items.push(
                <Dot index={i + images.length} slide={i + images.length} className={"preview-dot"}>
                    <Icon circular inverted color={"grey"} name={"play"}/>
                </Dot>
            )
        }

        return items;
    }

    handleSiteLinkChange(index, type, event, data) {
        switch (type) {
            case "link":
                this.state.images[index].site_link = data.value;
                break;
            case "header":
                this.state.images[index].slide_header = data.value;
                break;
            case "teaser":
                this.state.images[index].slide_teaser = data.value;
                break;
            default:
                break;
        }
        this.setState({...this.state})
    }

    saveSiteLink(index) {
        let self = this;

        let image = this.state.images[index];

        fetch(config.BASE_URL + 'images/site-link', {
            method: "PUT",
            body: JSON.stringify({
                site_link: image.site_link,
                slide_header: image.slide_header,
                slide_teaser: image.slide_teaser,
                id: image.id
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.fetchImagesFrontendId();
                self.fetchAllImages();
            }
        }).catch(() => {
        });

    }

    render() {
        const showStuff = this.getGalleryItems(this.state.images);
        //const showDots = this.getGalleryDots(images, videos);

        let showImages = this.state.images.map((image, index) =>
            <Grid.Column key={index} width={5}>
                <Button
                    negative
                    onClick={() => this.deleteImage(index)}
                    attached={"top"}
                    className={"gallery-delete-button"}>
                    Löschen
                </Button>
                <Image
                    src={image.link}
                    fluid
                    alt={"Slider Image" + index}
                />
                <Form>
                    <Form.Field>
                        <label>Header</label>
                        <Input
                            placeholder='Header'
                            value={this.state.images[index].slide_header}
                            onChange={this.handleSiteLinkChange.bind(this, index, "header")}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Teaser</label>
                        <Input
                            placeholder='Teaser'
                            value={this.state.images[index].slide_teaser}
                            onChange={this.handleSiteLinkChange.bind(this, index, "teaser")}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Link</label>
                        <Input
                            placeholder='Verlinkung'
                            value={this.state.images[index].site_link}
                            onChange={this.handleSiteLinkChange.bind(this, index, "link")}
                        />
                    </Form.Field>
                    <Button
                        type={"submit"}
                        icon color={"orange"}
                        onClick={() => this.saveSiteLink(index)}>
                        <Icon name={"save"}/>
                    </Button>
                </Form>

            </Grid.Column>
        );

        let showSelectImages = this.state.selectImages.map((item, index) =>
            <Grid.Column key={index} width={5}>
                <Image
                    src={item.link.replace('https://www.buergerstiftung-kreis-rv.de/','/')}
                    fluid
                    className={item.selected ? "selected-image" : "unselected-image"}
                    onClick={() => this.state.selectImages[index].selected = !this.state.selectImages[index].selected}
                />
                <Icon
                    className={item.selected ? "selected-label" : "unselected-label"}
                    name={item.selected ? "check circle outline" : "circle outline"}
                />
            </Grid.Column>
        );

        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        return (
            displayType === "admin" ?
                <Grid>
                    <Grid.Row>
                        {showImages}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <input type="file" id="file" className="inputfile"
                                   onChange={this.uploadThumbnailImage.bind(this)}
                                   accept="image/*"
                                   multiple/>
                            <Button.Group>
                                <Button
                                    color={"orange"}
                                    onClick={() => this.setState({...this.state, selectModalOpen: true})}>
                                    Auswählen
                                </Button>
                                <Button color={"teal"}>
                                    <label htmlFor="file">
                                        Hochladen
                                    </label>
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Modal open={this.state.selectModalOpen}
                           style={{"margin": "auto"}}
                           onClose={() => this.setState({...this.state, selectModalOpen: false})} centered={true}>
                        <Modal.Header>{"Auswählen"}</Modal.Header>
                        <Modal.Content scrolling>
                            <Grid>
                                <Grid.Row>
                                    {showSelectImages}
                                </Grid.Row>
                            </Grid>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative icon='delete'
                                    onClick={() => this.setState({...this.state, selectModalOpen: false})}/>
                            <Button
                                positive
                                icon='checkmark'
                                onClick={() => this.addSelectedImages()}
                            />
                        </Modal.Actions>
                    </Modal>
                </Grid>
                :
                <CarouselProvider
                    naturalSlideWidth={1}
                    naturalSlideHeight={0.5}
                    isPlaying={true}
                    infinite={true}
                    totalSlides={this.state.images.length}>
                    <Slider style={this.currentWidth <= MIN_WIDTH ? {overflow: "initial"} : {overflow: "hidden"}}>
                        {showStuff}
                    </Slider>
                    {this.state.images.length > 1 && displayType !== "admin" ?
                        <ButtonBack
                            className={"gallery-back-button"}>
                            <Icon name={'angle left'} inverted size={"large"} bordered circular/>
                        </ButtonBack>
                        :
                        null}
                    {this.state.images.length > 1 && displayType !== "admin" ?
                        <ButtonNext
                            className={"gallery-next-button"}>
                            <Icon name={'angle right'} inverted size={"large"} bordered circular/>
                        </ButtonNext>
                        :
                        null}
                </CarouselProvider>

        );
    }

}

/*

this.images.length > 1 ?
                    <div style={{"width": "100%", "textAlign": "center", "marginTop": "5px"}}>
                        {showDots}
                    </div>
                    :
                    null*/

ImageCarouselComponent.propTypes = {
    id: PropTypes.string
};

ImageCarouselComponent.defaultProps = {
    id: Math.random().toString()
};

export default ImageCarouselComponent