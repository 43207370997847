import React from "react";
import history from "../../helper/browserHistory";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import ImageCarouselComponent from "../../components/moap-components/ImageCarouselComponent";
import EditHeaderComponent from "../../components/moap-components/EditHeaderComponent";
import config from "../../config/main.config";
import EditTextComponent from "../../components/moap-components/EditTextComponent";
//import TitleImageComponent from "../../components/image/TitleImageComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

@observer
class AltTitleImageComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: {
                name: "",
                type: "",
                link: "",
                site_link: ""
            }
        }
    }


    componentDidMount() {
        this.fetchImagesFrontendId();
    }

    isArray(what) {
        return Object.prototype.toString.call(what) === '[object Array]';
    }

    fetchImagesFrontendId() {
        let frontendId = this.props.imageLinkId;
        fetch(config.BASE_URL + "images/frontendId/" + frontendId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (this.isArray(json) && json.length > 0)
                        this.setState({...this.state, image: json[0]})
                });

            } else {
                console.log(response.status)
            }
        }).catch(
            error => {
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    render() {
        const {headerId, subheaderId, imageLinkId} = this.props; //buttonTextId,buttonLinkId
        if (!headerId || !subheaderId || !imageLinkId) {
            console.log('Src is not acceptable');
            return null;
        }

        let isIE = true;
        if (typeof document !== "undefined") {
            isIE =  /*@cc_on!@*/ !!document.documentMode;
        }

        return (
            <Grid.Row>
                <Grid.Column width={2} only={"computer"}/>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                    <h2><EditHeaderComponent id={headerId} namespace={this.props.namespace}
                                             textStore={this.props.textStore}/></h2>
                    <p className={"text-block"}><EditTextComponent textStore={this.props.textStore} id={subheaderId}
                                                                   namespace={this.props.namespace}/></p>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    {isIE ?
                        <Image
                            fluid
                            src={this.state.image.link}
                            alt={"Slider Image"}
                            style={{cursor: "pointer"}}
                            onClick={() => this.state.image.site_link.length > 0 ?
                                window.open(this.state.image.site_link, "_blank") :
                                null
                            }
                        />
                        :
                        <ImageCarouselComponent id={imageLinkId}/>
                    }
                </Grid.Column>
            </Grid.Row>
        )
    }
}

AltTitleImageComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    headerId: PropTypes.string,
    buttonTextId: PropTypes.string,
    subheaderId: PropTypes.string,
    buttonLinkId: PropTypes.string,
    imageLinkId: PropTypes.string
};

AltTitleImageComponent.defaultProps = {
    textStore: {},
    namespace: "",
    headerId: "",
    buttonTextId: "",
    subheaderId: "",
    buttonLinkId: "",
    imageLinkId: ""
};

export default AltTitleImageComponent
