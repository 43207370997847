import React from "react";
import EditTextComponent from "../../components/moap-components/EditTextComponent";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import PropTypes from "prop-types";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Embed from "semantic-ui-react/dist/commonjs/modules/Embed";

class VideoAndDescriptionComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false
        }
    }

    render() {
        const {textId, buttonTextId, youtubeId, link} = this.props;
        if (!textId || !youtubeId || !link || !buttonTextId) {
            console.log('Src is not acceptable');
            return null;
        }
        const defaultImage = "https://www.buergerstiftung-kreis-rv.de/images/Buergerstiftung.jpg";

        return (
            <Grid>
                <Grid.Row style={{paddingTop: "0px"}}>
                    <Grid.Column>
                        <p className={"text-block"}>
                            <EditTextComponent textStore={this.props.textStore} id={textId}
                                               namespace={this.props.namespace}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {this.state.isPlaying ?
                            <Embed
                                brandedUI id={youtubeId}
                                source='youtube'
                                autoplay={true}
                                active={true}
                                aspectRatio={"16:9"}/>
                            :
                            <div
                                className={"video-placeholder"}
                                style={{background: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + defaultImage + "')"}}
                                onClick={() => {
                                    console.log("click")
                                    this.setState({isPlaying: true})
                                }}>
                                <Icon
                                    className={"video-play-button"}
                                    size={"massive"}
                                    inverted
                                    name={"video play"}/>
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

VideoAndDescriptionComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
};

VideoAndDescriptionComponent.defaultProps = {
    textStore: {},
    namespace: null
};

export default VideoAndDescriptionComponent
