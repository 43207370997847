import React from "react";
import {withTranslation} from "react-i18next";
import history from "../../helper/browserHistory";
import PropTypes from "prop-types";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Loadable from "react-loadable";
import LoaderComponent from "../../components/LoaderComponent";

const EditTextComponent = Loadable({
    loader: () => import(/* webpackChunkName: 'EditTextComponent' */ "../../components/moap-components/EditTextComponent"),
    loading: () => LoaderComponent
});


class StifterComponent extends React.Component {

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid.Row centered className={"page-row"}>
                <Grid.Column width={1} only={"computer"}/>
                <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                    <Image centered
                           src={Seperator}
                           width={80}
                           height={70}
                           size={"tiny"}
                           alt={"Separator Logo"}
                    />
                    <h2>Stifterbrief</h2>
                    <p className={"text-block"}>
                        <EditTextComponent id={"stifter-text-id"} namespace={this.props.namespace}
                                           textStore={this.props.textStore}/>
                    </p>
                </Grid.Column>
                <Grid.Column width={1} only={"computer"}/>
                <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"} style={{paddingTop: "1rem"}}>
                    <Button circular fluid className={"call-to-action-button"}
                            onClick={() => {
                                history.push("/stifterbrief")
                            }}>
                        ZUM STIFTERBRIEF
                    </Button>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

StifterComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
};

StifterComponent.defaultProps = {
    textStore: {},
    namespace: null
};

export default withTranslation()(StifterComponent)
