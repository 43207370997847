import React from "react";
import PropTypes from "prop-types";
import EditSimpleValueComponent from "../moap-components/EditSimpleValueComponent";
import EditIconButtonComponent from "../moap-components/EditIconButtonComponent";
import userStore from "../../stores/userStore";
import {observable} from "mobx";
import config from "../../config/main.config";
import {createText, getTokenFromLocalStorage, updateText} from "../../helper/util";
import {observer} from "mobx-react";
import {fetchTextById} from "../../../server/helper/ssrHelper";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

@observer
class CardImageComponent extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            editValue: ""
        }
        let ids = [this.props.headerId, this.props.imageLinkId, this.props.subheaderId, this.props.buttonTextId, this.props.buttonLinkId];
        for (let i = 0; i < ids.length; i++) {
            if (typeof this.props.textStore[ids[i]] === "undefined") {
                fetchTextById(ids[i]).then(text => {
                    if (typeof text !== "undefined") {
                        this.setState(prevState => ({
                            ...prevState,
                            textStore: {...prevState.textStore, [ids[i]]: text[ids[i]]}
                        }));
                    }
                })
            }
        }
    }

    handleChange(event, data) {
     this.setState({...this.state, editValue: data.value});
    }

    uploadThumbnailImage(event) {
        let self = this;
        const data = new FormData();
        data.append('image', event.target.files[0]);

        fetch(config.BASE_URL + 'images', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    console.log("response json: " + json.toString());
                    self.setState({...self.state, editValue: json.link});
                });

            }
        }).catch(() => {
        });
    }

    render() {
        const {headerId, imageLinkId, subheaderId, buttonTextId, buttonLinkId} = this.props;
        const textStore = this.state.textStore;
        if (!headerId || !subheaderId || !imageLinkId || !buttonTextId || !buttonLinkId) {
            console.log('Src is not acceptable');
            return null;
        }

        let textToShow = typeof textStore[imageLinkId] === "undefined" ? {content: ""} : textStore[imageLinkId]["de-DE"];

        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let editDiv = <div/>;
        if (displayType === "admin") {
            if (this.state.editState) {
                editDiv = <div>
                    <Form>
                        <Form.Input value={this.state.editValue} onChange={this.handleChange.bind(this)}/>
                    </Form>
                    <br/>
                    <input type="file" id={headerId} className="inputfile"
                           onChange={this.uploadThumbnailImage.bind(this)}
                           accept="image/*"/>
                    <label htmlFor={headerId} className={"input-label"}>
                        Hochladen
                    </label>

                    <Button.Group>
                        <Button color="green"
                                onClick={() => {

                                    textToShow = {
                                        content: this.state.editValue,
                                        title: "",
                                        description: ""
                                    };

                                    if (typeof textStore[imageLinkId] === "undefined" || typeof textStore[imageLinkId]["de-DE"] === "undefined") {
                                        createText(imageLinkId, textToShow, "de-DE", this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(imageLinkId).then(text => {
                                                    textStore[imageLinkId] = text[imageLinkId];
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    } else {
                                        updateText(imageLinkId, textToShow, "de-DE", this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(imageLinkId).then(text => {
                                                    textStore[imageLinkId] = text[imageLinkId];
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    }
                                }}>
                            Speichern
                        </Button>
                        <Button color="red" onClick={() => {
                            this.setState({...this.state, editState: false})
                        }}>Abbrechen</Button>

                    </Button.Group>
                </div>
            } else {
                editDiv = <div>
                    {textToShow.content}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.state.editValue = textToShow.content;
                        this.setState({...this.state, editState: true});
                    }}/>
                </div>
            }
        }

        return (
            <Grid.Column stretched computer={4} tablet={8} mobile={16}>
                <Card fluid className={"card-shadow"}>
                    {displayType === "admin" ?
                        editDiv
                        :
                        <Modal
                            style={{margin: "auto"}}
                            trigger={
                                <div className={"product-image-container"}>
                                    <Image
                                        className={"product-image"}
                                        style={{cursor: "zoom-in"}}
                                        src={textToShow.content}
                                        fluid
                                        alt={"Card Image"}
                                    />
                                </div>
                            } basic size='small'>
                            <Modal.Content>
                                <Image
                                    src={textToShow.content}
                                    size={"huge"}
                                    alt={"Large Card Image"}
                                />
                            </Modal.Content>
                        </Modal>

                    }
                    <Card.Content>
                        <Card.Header>
                            <h3><EditSimpleValueComponent textStore={this.state.textStore} id={headerId}
                                                          namespace={this.props.namespace}/></h3>
                        </Card.Header>
                        <Card.Description>
                            <p className={"text-block"}><EditSimpleValueComponent id={subheaderId}
                                                                                  textStore={this.state.textStore}
                                                                                  namespace={this.props.namespace}/></p>
                        </Card.Description>
                        <EditIconButtonComponent cssClass={"card-button card-button-position"} textId={buttonTextId}
                                                 textStore={this.state.textStore}
                                                 linkTextId={buttonLinkId} namespace={this.props.namespace}/>
                    </Card.Content>
                </Card>
            </Grid.Column>
        )
    }
}

CardImageComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    headerId: PropTypes.string,
    subheaderId: PropTypes.string,
    imageLinkId: PropTypes.string,
    buttonTextId: PropTypes.string,
    buttonLinkId: PropTypes.string,
};

CardImageComponent.defaultProps = {
    textStore: {},
    namespace: null,
    headerId: null,
    subheaderId: null,
    imageLinkId: null,
    buttonTextId: null,
    buttonLinkId: null
};

export default CardImageComponent