import React from "react";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

import PropTypes from "prop-types";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";

class CounterIconComponent extends React.Component {

    render() {
        const {headerId, iconNameId, subheaderId, color} = this.props;
        if (!headerId || !subheaderId || !iconNameId) {
            console.log('Src is not acceptable');
            return null;
        }

        let cssClass = "point-blue";
        if (color) {
            if (color === "orange") {
                cssClass = "point-orange"
            } else if (color === "light-blue") {
                cssClass = "point-light-blue"
            } else if (color === "yellow") {
                cssClass = "point-yellow"
            } else if (color === "green") {
                cssClass = "point-green"
            }
        }

        return (
            <Grid.Column computer={3} tablet={6} mobile={8} textAlign={"center"}>
                <div className={cssClass}>
                    <Image
                        width={90}
                        height={90}
                        src={"https://www.buergerstiftung-kreis-rv.de/images/iconset/white/" + iconNameId}
                        alt={"Information Icon"}
                    />
                </div>
                <h3><EditSimpleValueComponent id={headerId} namespace={this.props.namespace}
                                              textStore={this.props.textStore}/></h3>
                <p className={"text-block"}><EditSimpleValueComponent textStore={this.props.textStore} id={subheaderId}
                                                                      namespace={this.props.namespace}/></p>
            </Grid.Column>
        )
    }
}

CounterIconComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    headerId: PropTypes.string,
    subheaderId: PropTypes.string,
    iconNameId: PropTypes.string,
    color: PropTypes.string,
};

CounterIconComponent.defaultProps = {
    textStore: {},
    namespace: null,
    headerId: null,
    subheaderId: null,
    iconNameId: null,
    color: null
};

export default CounterIconComponent