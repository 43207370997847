import React from "react";
import { inject, observer } from "mobx-react";
import browserHistory from "../../helper/browserHistory";
import CounterIconComponent from "./CounterIconComponent";
import BlogArticleCardComponent from "../blog/BlogArticleCardComponent";
import EditTextComponent from "../../components/moap-components/EditTextComponent";
import VideoAndDescriptionComponent from "./VideoAndDescriptionComponent";
import CardIconComponent from "../../components/card/CardIconComponent";
import CardImageComponent from "../../components/card/CardImageComponent";
import config from "../../config/main.config";
import AltTitleImageComponent from "./AltTitleImageComponent";
import StifterComponent from "./StifterComponent";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import { withTranslation } from "react-i18next";

const NETWORK_CATEGORY = "1b8868e9-2932-4618-a274-40b5bd323c64";
const PAGE_LINK = "HOME";

@inject("stores")
@observer
class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            textStore: props.stores.textStore.texts,
            articles: []
        }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);

        // Get Blog dates from Server
        fetch(config.BASE_URL + 'blog/amount-articles/' + 3, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                //    console.log(data);
                if (data.hasOwnProperty("articles")) {
                    //Sort Articles
                    this.setState({ ...this.state, articles: data.articles })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        window.scrollTo(0, 0);
    }


    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";
        let showArticles = [];
        for (let j = 0; j < this.state.articles.length; j++) {
            if (!this.state.articles[j].categoryIds.includes(NETWORK_CATEGORY)) {
                let article = this.state.articles[j];
                showArticles.push(<Grid.Column computer={4} tablet={8} mobile={16} stretched key={j}>
                    <BlogArticleCardComponent
                        titleText={article.header}
                        teaserText={article.description}
                        imageLink={article.thumbnail}
                        buttonLink={article.id}
                        dateText={article.createdAt}
                        tags={article.tags}
                    />
                </Grid.Column>)
            }
        }

        return (
            <Grid>
                <AltTitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'home'}
                    imageLinkId={"home-title-image-id"}
                    headerId={"home-title-header-id"}
                    subheaderId={"home-subtitle-header-id"}
                    buttonLinkId={"home-title-button-link-id"}
                    buttonTextId={"home-title-button-text-id"}
                />
                <StifterComponent namespace={'home'} textStore={this.state.textStore} />
                <Grid.Row centered className={"home-page-top-row"}>
                    <CounterIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={"counter-1-header-id"}
                        subheaderId={"counter-1-subheader-id"}
                        color={"blue"}
                    />
                    <CounterIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Moneywhite.svg"}
                        headerId={"counter-2-header-id"}
                        subheaderId={"counter-2-subheader-id"}
                        color={"green"}
                    />
                    <CounterIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Statswhite.svg"}
                        headerId={"counter-3-header-id"}
                        subheaderId={"counter-3-subheader-id"}
                        color={"light-blue"}
                    />
                    <CounterIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={"counter-4-header-id"}
                        subheaderId={"counter-4-subheader-id"}
                        color={"orange"}
                    />
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                            width={80}
                            height={70}
                            src={Seperator}
                            size={"tiny"}
                            alt={"Separator Logo"}
                        />
                        <h2>Aktuelles</h2>
                        <p className={"text-block"}>

                            <EditTextComponent textStore={this.state.textStore} id={"home-current-sub-description-id"}
                                namespace={'home'} />
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'} />
                    {showArticles}
                    <Grid.Column width={2} only={'computer'} />
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}>

                        <Button circular fluid className={"call-to-action-button"}
                            onClick={() => {
                                browserHistory.push("/current");
                                this.setState({});
                            }}>
                            MEHR AKTUELLES
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                            src={Seperator}
                            width={80}
                            height={70}
                            size={"tiny"}
                            alt={"Separator Logo"}
                        />
                        <h2>Förderung erhalten</h2>
                        <p className={"text-block"}>

                            <EditTextComponent textStore={this.state.textStore} id={"home-get-money-sub-description-id"}
                                namespace={'home'} />
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <VideoAndDescriptionComponent
                            textStore={this.state.textStore}
                            namespace={'home'}
                            textId="home_text_id"
                            youtubeId="k0Y6a8Somkw"
                            buttonTextId="home_button_id"
                            link="/about" />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                    <Grid.Column width={2} only={'computer'} />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Helpwhite.svg"}
                        headerId={"home-icon-card-1-header-id"}
                        subheaderId={"home-icon-card-1-subheader-id"}
                        color={"green"}
                        buttonTextId={"home-icon-card-1-button-text-id"}
                        buttonLinkId={"home-icon-card-1-button-link-id"}
                        infoTextId={"home-icon-card-1-info-text-id"}
                        bottomButtonLink={"https://www.buergerstiftung-kreis-rv.de/downloads/BSKR_Antrag_Einzelfallhilfe.pdf"}
                        bottomButtonText={"Einzelfallhilfe beantragen"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={"home-icon-card-2-header-id"}
                        subheaderId={"home-icon-card-2-subheader-id"}
                        color={"light-blue"}
                        buttonTextId={"home-icon-card-2-button-text-id"}
                        buttonLinkId={"home-icon-card-2-button-link-id"}
                        infoTextId={"home-icon-card-2-info-text-id"}
                        bottomButtonLink={"https://www.buergerstiftung-kreis-rv.de/downloads/BSKR_Antrag_Projektfoerderung.pdf"}
                        bottomButtonText={"Projektförderung beantragen"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Futurewhite.svg"}
                        headerId={"home-icon-card-3-header-id"}
                        subheaderId={"home-icon-card-3-subheader-id"}
                        color={"orange"}
                        buttonTextId={"home-icon-card-3-button-text-id"}
                        buttonLinkId={"home-icon-card-3-button-link-id"}
                        infoTextId={"home-icon-card-3-info-text-id"}
                        bottomButtonLink={"https://www.buergerstiftung-kreis-rv.de/downloads/BSKR_Antrag_Projektfoerderung.pdf"}
                        bottomButtonText={"Projektförderung beantragen"}
                    />
                    <Grid.Column width={2} only={'computer'} />
                </Grid.Row>

                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                    <h2>Partnerschaft</h2>
                        <EditTextComponent textStore={this.state.textStore} id={"home-partnerschaft-text-1-id"} namespace={'home'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}
                        className={"page-title-button"}>
                        <Button circular fluid className={"call-to-action-button"}
                            onClick={() => {
                                window.open("/partner", "_blank")
                            }}>
                            Unsere Partner
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                            width={80}
                            height={70}
                            src={Seperator}
                            size={"tiny"}
                            alt={"Separator Logo"}
                        />
                        <h2>Aktiv mitwirken</h2>
                        <p className={"text-block"}>

                            <EditTextComponent textStore={this.state.textStore} id={"home-get-active-description-id"}
                                namespace={'home'} />
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={2} only={'computer'} />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Moneywhite.svg"}
                        headerId={"home-icon-card-4-header-id"}
                        subheaderId={"home-icon-card-4-subheader-id"}
                        color={"green"}
                        buttonTextId={"home-icon-card-4-button-text-id"}
                        buttonLinkId={"home-icon-card-4-button-link-id"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={"home-icon-card-5-header-id"}
                        subheaderId={"home-icon-card-5-subheader-id"}
                        color={"light-blue"}
                        buttonTextId={"home-icon-card-5-button-text-id"}
                        buttonLinkId={"home-icon-card-5-button-link-id"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Peoplewhite.svg"}
                        headerId={"home-icon-card-6-header-id"}
                        subheaderId={"home-icon-card-6-subheader-id"}
                        color={"orange"}
                        buttonTextId={"home-icon-card-6-button-text-id"}
                        buttonLinkId={"home-icon-card-6-button-link-id"}
                    />
                    <Grid.Column width={2} only={'computer'} />
                    <Grid.Column width={2} only={'computer'} />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={"home-icon-card-7-header-id"}
                        subheaderId={"home-icon-card-7-subheader-id"}
                        color={"red"}
                        buttonTextId={"home-icon-card-7-button-text-id"}
                        buttonLinkId={"home-icon-card-7-button-link-id"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Ideawhite.svg"}
                        headerId={"home-icon-card-8-header-id"}
                        subheaderId={"home-icon-card-8-subheader-id"}
                        color={"yellow"}
                        buttonTextId={"home-icon-card-8-button-text-id"}
                        buttonLinkId={"home-icon-card-8-button-link-id"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Peoplewhite.svg"}
                        headerId={"home-icon-card-9-header-id"}
                        subheaderId={"home-icon-card-9-subheader-id"}
                        color={"green"}
                        buttonTextId={"home-icon-card-9-button-text-id"}
                        buttonLinkId={"home-icon-card-9-button-link-id"}
                    />
                    <Grid.Column width={2} only={'computer'} />
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}
                        className={"page-title-button"}>

                        <Button circular fluid className={"call-to-action-button"}
                            onClick={() => {
                                browserHistory.push("/contact");
                                this.setState({});
                            }}>
                            {"sprechen Sie uns an".toUpperCase()}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                            src={Seperator}
                            width={80}
                            height={70}
                            size={"tiny"}
                            alt={"Separator Logo"}
                        />
                        <h2>Über uns</h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column width={2} only={'computer'} />
                    <CardImageComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        headerId={"home-card-image-header-1-id"}
                        subheaderId={"home-card-image-sub-header-1-id"}
                        imageLinkId={"home-card-image-link-1-id"}
                        buttonTextId={"home-card-image-1-button-text-id"}
                        buttonLinkId={"home-card-image-1-button-link-id"}
                    />
                    <CardImageComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        headerId={"home-card-image-header-2-id"}
                        subheaderId={"home-card-image-sub-header-2-id"}
                        imageLinkId={"home-card-image-link-2-id"}
                        buttonTextId={"home-card-image-2-button-text-id"}
                        buttonLinkId={"home-card-image-2-button-link-id"}
                    />
                    <CardImageComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        headerId={"home-card-image-header-3-id"}
                        subheaderId={"home-card-image-sub-header-3-id"}
                        imageLinkId={"home-card-image-link-3-id"}
                        buttonTextId={"home-card-image-3-button-text-id"}
                        buttonLinkId={"home-card-image-3-button-link-id"}
                    />
                    <Grid.Column width={2} only={'computer'} />
                </Grid.Row>
            </Grid>
        );
    }
}


export default withTranslation()(HomePage)